<template>
    <div class="hello">
    <!-- <div> -->
      <!-- <img src="../assets/banner.jpg" class="img-resp banner-img-mar-set" /> -->
<div class="sep-white"></div>
<div class="sep-white"></div>
<div class="sep-white"></div>
<div class="hero-t">

      </div>
<div class="sep-white"></div>

          <div class="bg1-cont">
      <div class="container" id="">
        <div class="text-box ml-auto mr-box"
          data-aos="fade-right"
>
          <p>
We continue to follow the latest market trends when it comes to  diamonds.
We are introducing a new method of tokenizing diamonds. This platform will enable our clients to earn more revenue.
The diamond industry is about to experience a paradigm shift as a new way of  tokenizing a diamond will be more accessible and help change the diamond  industry.
          </p>
        </div>
      </div>
    </div>

      <div class="hero-svg">
          <div class="hero-heading-hldr" data-aos="fade-right">
            <h2 class="heading-svg">Your gateway to the future of finance</h2>
            <p class="heading-svg">Raven star offers a new way to tokenize diamonds in addition to many other  services. 
With this platform, customers will be able to generate more revenue. 
Tokenizing a diamond is an unparalleled way to give diamonds liquidity and  expose them to a comprehensive international marketplace. 
Once tokenized, the diamond will still belong to you the ultimate owner of the  diamond but will be physically stored with a tier one storage facility like  Brinks, Transguard.  
You will receive a digital token that represents the rights to the diamond that  with Raven star helps can then be traded and generate a healthy amount of  profit.</p>
            <a href="#"><button class="hero-svg-btn">Learn More <b class="btn-arrow">➝</b></button></a>
          </div>
      </div>
<div class="sep-white rust-bg" id=""></div>

<div class="txt-info" data-aos="fade-up">
    <h2>Partner With the Best</h2>
    <p>RavenStar is the leading technology platform for issuers of blockchain assets.</p>
</div>

<div class="sq-box-hldr" data-aos="fade-up">
    <div class="sq-box"><div class="sq-img"><img src="https://img.icons8.com/ios-glyphs/40/ffffff/queue.png"/></div>
    <h2>300k+</h2>
    <p>Investors Processed</p>
    </div>
    <div class="sq-box"><div class="sq-img"><img src="https://img.icons8.com/ios-glyphs/40/ffffff/pulse.png"/></div>
    <h2>$30B+</h2>
    <p>Launched Customer Market Cap</p>
    </div>
    <div class="sq-box"><div class="sq-img"><img src="https://img.icons8.com/ios-glyphs/40/ffffff/city-buildings.png"/></div>
    <h2>50+</h2>
    <p>Assets Created</p>
    </div>
    <div class="sq-box"><div class="sq-img"><img src="https://img.icons8.com/ios-glyphs/40/ffffff/ledger.png"/></div>
    <h2>$360m</h2>
    <p>Investor purchases through RavenStar</p>
    </div>
</div>

<div class="txt-info" data-aos="fade-up">
    <h2>RavenStar at a Glance</h2>
    <p>We are committed to providing a secure, scalable and compliant solution for getting digital assets to market.</p>
</div>

    <div class="img-text-hldr rust-bg">
        <div class="img-txt-img" data-aos="zoom-in"><img src="~@/assets/sec-a.svg" /></div>
        <div class="img-txt-txt img-txt-l" data-aos="fade-right">
            <div class="txt-left">
                <h2 class="heading-svg">Layer-1 Protocols Use RavenStar</h2>
                <p class="heading-svg">Layer one protocols use RavenStar to fundraise and bootstrap their community. We've helped launch projects like Tezos, Hashgraph and Avalanche who used our platform to raise almost half a billion in aggregate and process over a hundred thousand investors.
<br>
RavenStar remains the easiest way to launch a layer one protocol in the market.</p>
                <a href="#"><button class="hero-svg-btn">Learn More <b class="btn-arrow">➝</b></button></a>
            </div>
        </div>
    </div>

    <div class="img-text-hldr">
        <div class="img-txt-txt img-txt-r" data-aos="fade-right">
            <div class="txt-left">
                <h2 class="heading-svg">DeFi Projects Use RavenStar</h2>
                <p class="heading-svg">Whether you're building a defi protocol on Ethereum, Polkadot, Avalanche or your favorite layer one. We're here to help build your community and get you to market faster. We provide payment support in the native blockchain so you can better connect with the community members on your favorite layer one.
<br>
Read more about our recently launched Gnosis Auction integration here.</p>
                <a href="#"><button class="hero-svg-btn">Learn More <b class="btn-arrow">➝</b></button></a>
            </div>
        </div>
        <div class="img-txt-img" data-aos="zoom-in"><img src="~@/assets/sec-b.svg" /></div>
    </div>

    <div class="img-text-hldr rust-bg">
        <div class="img-txt-img" data-aos="zoom-in"><img src="~@/assets/sec-c.svg" /></div>
        <div class="img-txt-txt img-txt-l" data-aos="fade-right">
            <div class="txt-left">
                <h2 class="heading-svg">Wrapped by RavenStar</h2>
                <p class="heading-svg">wrapped.com, a product line by RavenStar, enables a cross-chain institutional bridge for layer-one protocols. RavenStar is used to manage the life-cycle of digital assets as they integrate with DeFi protocols on Polkadot, Celo, Stacks and Kadena.</p>
                <a href="#"><button class="hero-svg-btn">Learn More <b class="btn-arrow">➝</b></button></a>
            </div>
        </div>
    </div>

    <div class="img-text-hldr">
        <div class="img-txt-txt img-txt-r" data-aos="fade-right">
            <div class="txt-left">
                <h2 class="heading-svg">RavenStar for financial institutions</h2>
                <p class="heading-svg">Financial institutions use RavenStar to create digital assets. RavenStar was the first to bring SEC-registered assets to market with Arca's ArCoin and INX. RavenStar's tokens for financial institutions are the most widely traded today.
<br>
Through a third-party distributor, RavenStar was first to tokenize equity under Switzerland's FINMA regulations.</p>
                <a href="#"><button class="hero-svg-btn">Learn More <b class="btn-arrow">➝</b></button></a>
            </div>
        </div>
        <div class="img-txt-img" data-aos="zoom-in"><img src="~@/assets/sec-d.svg" /></div>
    </div>

    <!-- <div class="bg2-cont">
      <div class="container" id="">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <h2>Mining free</h2>
          <p>
Lab-created diamonds are an environmentally friendly choice since mining is not required.
          </p>
        </div>
      </div>
    </div>
    <div class="sep-white" id="beauty"></div>

    <div class="bg3-cont">
      <div class="container" >
        <div class="text-box ml-auto mr-box"
          data-aos="fade-right">
        <h2>Beauty & Quality</h2>
        <p>
          The physical, chemical, and optical properties of man-made diamonds are similar to those of mined diamonds.
        </p>
        </div>
      </div>
    </div>
    <div class="sep-white" id="value"></div>

    <div class="bg4-cont">
      <div class="container">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <h2>Value</h2>
          <p>Compared to natural diamonds of comparable size and quality, lab-created diamonds are slightly more affordable and offer excellent value for money.</p>
        </div>
      </div>
    </div>  
    <div class="sep-white" id="man" ></div>

        <div class="bg5-cont">
      <div class="container">
        <div class="text-box ml-auto mr-box"
          data-aos="fade-right">
        <h2>What Are Man-Made Diamonds?</h2>
        <p>
          Man-made diamonds, also known as engineered diamonds or farmed diamonds, are grown in a highly controlled laboratory environment, using advanced technology to replicate the natural development conditions when diamonds are formed in the lower mantle of the earth's crust.<br>
These synthetic diamonds are made up of real carbon atoms organized in the diamond crystal structure.
They have the same visual and chemical qualities as natural diamonds because they are manufactured of the same substance.</p>
        </div>
      </div>
    </div>  
    <div class="sep-white" id=""></div>

        <div class="bg6-cont">
      <div class="container">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <p>Our lab-grown diamonds now have a variety of colorless series to choose from.

Cultured diamonds are additionally on hand in fancy colorations that are regarded very uncommon in nature, along with famous shades of vivid fancy yellow.
Compared with natural colored diamonds, the price of colored laboratory diamonds is relatively reasonable.</p>
        </div>
      </div>
    </div>   -->
    <div class="sep-white"></div>

  </div>
</template>

<script>
export default {
  name: 'TokenComp',
}
</script>

<style scoped lang="scss">

// .logo-align{
//   text-align: right;
// }

.img-resp{
  max-width: 100%;
  width: auto;
}

.banner-img-mar-set{
  margin-top: 85px;
  margin-bottom: 15px;
}

.sep-white{
  width: 100%;
  height: 20px;
  background: #fff;
}

.rust-bg{
    background: #3c312c;
    & h2, p {
        color: #fff;
    }
}

.hero-t{
  background: url('~@/assets/hero-t.jpg') center right no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover;
  // margin-bottom: 20px;
}

.bg1-cont{
  text-align: left;
  background: url('~@/assets/bg-1t.jpg') left 5vw center no-repeat #02191f;
  -webkit-background-size: 43%;
  -moz-background-size: 43%;
  -o-background-size: 43%;
  background-size: 43%;
  padding: 150px 0;
}

.hero-svg{
  background: url('~@/assets/hero.svg') center right 10% no-repeat;
  width: 100%;
  height: 500px;
  background-size: 480px;
  // margin-bottom: 20px;
}

.hero-heading-hldr{
    padding: 120px 0 0 90px;
    text-align: left;
}

.heading-svg{
    color: #000;
    width: 600px;
    text-align: justify;
}

.hero-svg-btn{
    border: 0;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    background: #334d5e;
    border-radius: 8px;
    cursor: pointer;
    transition: ease-in-out;
    margin-top: 15px;
    &:hover .btn-arrow{
        margin-left: 8px;
        
    }
}

.btn-arrow{
    margin-left: 5px;
    transition: 500ms margin-left ease-in-out;
}

.sq-box-hldr{
    display: flex;
    align-items: center;
    justify-content: center;
}

.sq-box{
    width: 200px;
    min-height: 200px;
    margin: 10px;
    border-radius: 8px;
    padding: 15px 5px 0;
    background: #334d5e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sq-box p{
    text-align: center;
    margin-bottom: 15px;
}

.txt-info{
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
}

.txt-info h2, .txt-info p {
    color: #000;
}

.img-text-hldr{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    min-height: 250px;
}

.img-txt-txt{
    max-width: 600px;
}

.txt-left{
    text-align: left;
}

.img-txt-img img{
    width: 200px;
}

.img-txt-l{
    margin-left: 25px;
}

.img-txt-r{
    margin-right: 25px;
}

.bg2-cont{
  text-align: left;
  background: url('~@/assets/bg-1l.jpg') 30vw center no-repeat #334d5e;
  -webkit-background-size: 70%;
  -moz-background-size: 70%;
  -o-background-size: 70%;
  background-size: 70%;
  padding: 70px 0 90px;
  // margin-bottom: 20px;
}
.bg3-cont{
  text-align: left;
  background: url('~@/assets/bg-2l.jpg') -10vw center no-repeat #3c312c;
  -webkit-background-size: 80%;
  -moz-background-size: 80%;
  -o-background-size: 80%;
  background-size: 80%;
  background-size: 80%;
  padding: 70px 0 90px;
  // margin-bottom: 20px;
}
.bg4-cont{
  text-align: left;
  background: url('~@/assets/bg-3l.jpg') 30vw center no-repeat #334d5e;
  -webkit-background-size: 70%;
  -moz-background-size: 70%;
  -o-background-size: 70%;
  background-size: 70%;
  padding: 70px 0 90px;
  // margin-bottom: 20px;
}
.bg5-cont{
  text-align: left;
  background: url('~@/assets/bg-4l.jpg') -10vw center no-repeat #3c312c;
  -webkit-background-size: 80%;
  -moz-background-size: 80%;
  -o-background-size: 80%;
  background-size: 80%;
  background-size: 80%;
  padding: 20px 0 50px;
  // margin-bottom: 20px;
}
.bg6-cont{
  text-align: left;
  background: url('~@/assets/bg-5l.png') right 4vw center no-repeat #334d5e;
  -webkit-background-size: 57%;
  -moz-background-size: 57%;
  -o-background-size: 57%;
  background-size: 57%;
  padding: 70px 0;
  // margin-bottom: 20px;
}

.text-box{
  width: 300px;
}

.ml-box{
  margin-left: 80px;
}

.mr-box{
  margin-right: 80px;
}

.ml-auto{
  margin-left: auto;
}

@media screen and (max-width: 1345px) {
  
  .ml-box{
    margin-left: 25px;
  }

  .mr-box{
    margin-right: 25px;
  }

}

[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
  }
  @media screen and (min-width: 768px) {
    transform: translateX(100px);
    &.aos-animate {
      transform: translateX(0);
    }
  }
}
// @media (max-width: 768px) {
//   .hideOverflowOnMobile {
//     overflow: hidden;
//   }
// }
// .card {
//   overflow-x: hidden;
// }
// h3 {
//   margin: 40px 0 0;
// }
// ul {
//   list-style-type: none;
//   padding: 0;
// }
// li {
//   display: inline-block;
//   margin: 0 10px;
// }
</style>