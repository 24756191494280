<template>
  <div class="home" id="home">

    <TokenComp />
  </div>
</template>

<script>
// @ is an alias to /src
import TokenComp from '@/components/TokenComp.vue'

export default {
  name: 'Token',
  components: {
    TokenComp
  }
}
</script>
<style scoped>

</style>