<template>
  <div class="home" id="home">

    <LabComp />
  </div>
</template>

<script>
// @ is an alias to /src
import LabComp from '@/components/LabComp.vue'

export default {
  name: 'Lab',
  components: {
    LabComp
  }
}
</script>
<style scoped>

</style>