<template>
  <div class="hello">
    <!-- <div> -->
      <!-- <img src="../assets/banner.jpg" class="img-resp banner-img-mar-set" /> -->
      <div class="hero-l">

      </div>
<div class="sep-white" id="free"></div>

    <div class="bg2-cont">
      <div class="container" id="">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <h2>Mining free</h2>
          <p>
Lab-created diamonds are an environmentally friendly choice since mining is not required.
          </p>
        </div>
      </div>
    </div>
    <div class="sep-white" id="beauty"></div>

    <div class="bg3-cont">
      <div class="container" >
        <div class="text-box ml-auto mr-box"
          data-aos="fade-right">
        <h2>Beauty & Quality</h2>
        <p>
          The physical, chemical, and optical properties of man-made diamonds are similar to those of mined diamonds.
        </p>
        </div>
      </div>
    </div>
    <div class="sep-white" id="value"></div>

    <div class="bg4-cont">
      <div class="container">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <h2>Value</h2>
          <p>Compared to natural diamonds of comparable size and quality, lab-created diamonds are slightly more affordable and offer excellent value for money.</p>
        </div>
      </div>
    </div>  
    <div class="sep-white" id="man" ></div>

        <div class="bg5-cont">
      <div class="container">
        <div class="text-box ml-auto mr-box"
          data-aos="fade-right">
        <h2>What Are Man-Made Diamonds?</h2>
        <p>
          Man-made diamonds, also known as engineered diamonds or farmed diamonds, are grown in a highly controlled laboratory environment, using advanced technology to replicate the natural development conditions when diamonds are formed in the lower mantle of the earth's crust.<br>
These synthetic diamonds are made up of real carbon atoms organized in the diamond crystal structure.
They have the same visual and chemical qualities as natural diamonds because they are manufactured of the same substance.</p>
        </div>
      </div>
    </div>  
    <div class="sep-white" id=""></div>

        <div class="bg6-cont">
      <div class="container">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <!-- <h2>Management</h2> -->
          <p>Our lab-grown diamonds now have a variety of colorless series to choose from.

Cultured diamonds are additionally on hand in fancy colorations that are regarded very uncommon in nature, along with famous shades of vivid fancy yellow.
Compared with natural colored diamonds, the price of colored laboratory diamonds is relatively reasonable.</p>
        </div>
      </div>
    </div>  
    <div class="sep-white"></div>

  </div>
</template>

<script>
export default {
  name: 'LabComp',
}
</script>

<style scoped lang="scss">

// .logo-align{
//   text-align: right;
// }

.img-resp{
  max-width: 100%;
  width: auto;
}

.banner-img-mar-set{
  margin-top: 85px;
  margin-bottom: 15px;
}

.sep-white{
  width: 100%;
  height: 20px;
  background: #fff;
}

.hero-l{
  background: url('~@/assets/hero-l.jpg') center right no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover;
  // margin-bottom: 20px;
}

.bg2-cont{
  text-align: left;
  background: url('~@/assets/bg-1l.jpg') 30vw center no-repeat #334d5e;
  -webkit-background-size: 70%;
  -moz-background-size: 70%;
  -o-background-size: 70%;
  background-size: 70%;
  padding: 70px 0 90px;
  // margin-bottom: 20px;
}
.bg3-cont{
  text-align: left;
  background: url('~@/assets/bg-2l.jpg') -10vw center no-repeat #3c312c;
  -webkit-background-size: 80%;
  -moz-background-size: 80%;
  -o-background-size: 80%;
  background-size: 80%;
  background-size: 80%;
  padding: 70px 0 90px;
  // margin-bottom: 20px;
}
.bg4-cont{
  text-align: left;
  background: url('~@/assets/bg-3l.jpg') 30vw center no-repeat #334d5e;
  -webkit-background-size: 70%;
  -moz-background-size: 70%;
  -o-background-size: 70%;
  background-size: 70%;
  padding: 70px 0 90px;
  // margin-bottom: 20px;
}
.bg5-cont{
  text-align: left;
  background: url('~@/assets/bg-4l.jpg') -10vw center no-repeat #3c312c;
  -webkit-background-size: 80%;
  -moz-background-size: 80%;
  -o-background-size: 80%;
  background-size: 80%;
  background-size: 80%;
  padding: 20px 0 50px;
  // margin-bottom: 20px;
}
.bg6-cont{
  text-align: left;
  background: url('~@/assets/bg-5l.png') right 4vw center no-repeat #334d5e;
  -webkit-background-size: 57%;
  -moz-background-size: 57%;
  -o-background-size: 57%;
  background-size: 57%;
  padding: 70px 0;
  // margin-bottom: 20px;
}

.text-box{
  width: 300px;
}

.ml-box{
  margin-left: 80px;
}

.mr-box{
  margin-right: 80px;
}

.ml-auto{
  margin-left: auto;
}

@media screen and (max-width: 1345px) {
  
  .ml-box{
    margin-left: 25px;
  }

  .mr-box{
    margin-right: 25px;
  }

}

[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
  }
  @media screen and (min-width: 768px) {
    transform: translateX(100px);
    &.aos-animate {
      transform: translateX(0);
    }
  }
}
// @media (max-width: 768px) {
//   .hideOverflowOnMobile {
//     overflow: hidden;
//   }
// }
// .card {
//   overflow-x: hidden;
// }
// h3 {
//   margin: 40px 0 0;
// }
// ul {
//   list-style-type: none;
//   padding: 0;
// }
// li {
//   display: inline-block;
//   margin: 0 10px;
// }
</style>